<template>
  <div v-if="items.length" class="pt-4">
    <div class="font-weight-bold title-data-ai">Câu hỏi liên quan</div>
    <div v-for="(question,index) in items" :key="question.id">
      <template v-if="index < limit">
        <div class="form-question-answer-ai">
          <div class="title-question">Câu hỏi</div>
          <div class="content-question">
            <vue-mathjax v-if="question.is_formula" :formula="replaceSpace(question.question_content)" :safe="false"></vue-mathjax>
            <span v-else v-html="replaceSpace(question.question_content)"></span>
          </div>
          <div class="image-question" v-if="question.question_images.length">
            <viewer :images="question.question_images" :options="options" class="image-container d-flex">
              <img v-for="image in question.question_images" :src="image" :key="image">
            </viewer>
          </div>
          <div class="title-answer" v-if="question.answer_content">Trả lời</div>
          <div class="content-answer">
            <vue-mathjax v-if="question.is_formula" :formula="replaceSpace(question.answer_content)" :safe="false"></vue-mathjax>
            <span v-else v-html="replaceSpace(question.answer_content)"></span>
          </div>
          <div class="image-answer" v-if="question.answer_images.length">
            <viewer :images="question.answer_images" :options="options" class="image-container d-flex">
              <img v-for="image in question.answer_images" :src="image" :key="image">
            </viewer>
          </div>
          <!-- <div class="line-vote"></div> -->
          <!-- <Vote :active="question.rating ? question.rating : 0" :index="index" :question_id="question.id" @userVote="userVote"/> -->
        </div>
      </template>
    </div>
    <div v-if="items && limit < items.length" class="loadmore" @click="loadMore()">
      <span>Xem thêm <b-icon icon="arrow-right" scale="1.2"></b-icon></span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Vote from '~/components/mlearn/question/vote.vue'
export default {
  components: {
    // Vote
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      limit: 3,
      options: {
        navbar: false,
        title: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          rotateRight: true,
          rotateLeft: true
        }
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    replaceSpace (str) {
      // str = str.replace(/\s{2,}/g, ' ');
      // str = str.replace(/\t/g, ' ');
      // str = str.replace(/(\r\n|\n|\r)/g,"<br>");
      // str = str.toString().trim();
      // str = str.replace(/(\r\n|\n|\r)/g,"");
      return str
    },
    loadMore () {
      this.limit = this.limit + 3
    },
    userVote (index, value) {
      this.$emit('userVote', index, value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .title-data-ai{
    font-size: 26px;
  }
  .title-sub-data-ai{
    font-size: 16px;
  }
  .form-question-answer-ai{
    margin: 16px 0;
    padding: 20px;
    border-radius: 3px;
    border: solid 1px #e1e7f5;
    background-color: var(--white);
    .title-question{
      font-size: 18px;
      font-weight: bold;
    }
    .title-answer{
      padding-top:20px;
      font-size: 18px;
      font-weight: bold;
      color: #4086ff;
    }
    .content-question{
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #0f1b31;
      white-space: pre-wrap;
    }
    .content-answer{
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      white-space: pre-wrap;
    }
    .image-question{
      border-radius: 4px;
      border: solid 1px #dce2ef;
      margin: 10px 0;
      padding: 15px;
    }
    .image-answer{
      border-radius: 4px;
      border: solid 1px #dce2ef;
      margin: 10px 0;
      padding: 15px;
    }
    .line-vote{
      margin: 12px 0;
      border: 1px solid #E8ECF5;
    }
  }
  .loadmore{
    margin: 16px 13px 16px 0;
    border-radius: 3px;
    background: var(--white);
    box-shadow: 0px 1px 4px rgba(61, 61, 61, 0.54);
    // background-color: #dce2ef;
    font-weight: 500;
    text-align: center;
    height: 38px;
    line-height: 38px;
    cursor: pointer;
    span {
      font-size: 16px;
      cursor: pointer;
    }
  }
  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      cursor: zoom-in;
      max-height: 300px;
    }
  }
  @media (max-width: 768px){
    .title-data-ai{
      font-size: 20px;
    }
    .form-question-answer-ai {
      padding: 10px 15px;
      .title-question {
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 6px;
      }
      .content-question, .content-answer {
        font-weight: normal;
      }
      .title-answer {
        padding-top: 10px;
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 6px;
        line-height: 20px;
        color: #4086FF;
      }
    }
    .text-vote {
      font-size: 12px;
    }
  }
</style>
