<template>
  <div v-if="item" class="rank-item form-data align-items-center" :class="{'home': home}">
    <span class="number font-weight-bold" :class="'top-'+ind">{{ item.rankIndex }}</span>
    <img :src="item.avatar || NoAvatar" alt="avatar" class="avatar rounded-circle"/>
    <div class="content">
      <div class="display-html display-1-line">
        {{ hideNumber(item.fullName) }}
      </div>
    </div>
    <div class="pl-2 point text-right">{{ item.point }}</div>
  </div>
</template>

<script>
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    ind: {
      type: Number,
      default () {
        return 0
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    hideNumber (name) {
      if (/^\d+$/.test(name.trim()) && name.length >= 4) {
        name = name.slice(0, -4) + '***'
      }
      return name
    }
  },
  data () {
    return {
      NoAvatar
    }
  }
}
</script>

<style lang="scss" scoped>
.rank-item {
  padding-top: 15px;
  padding-bottom: 10px;
  @media (max-width: 1366px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media (max-width: 576px) {
  }

  &.rank-top {
    box-shadow: 0px 2px 8px rgba(116, 149, 211, 0.37);
    border-radius: 4px;
  }

  .number {
    color: #65676B;
    min-width: 70px;
    font-size: 16px;
    padding-left: 20px;
    @media (max-width: 1366px) {
      min-width: 20px;
      font-size: 13px;
      padding-left: 8px;
    }
    @media (max-width: 576px) {
      min-width: 50px;
      font-size: 14px;
      padding-left: 10px;
    }

    &.top-0 {
      color: #FCB431;
    }

    &.top-1 {
      color: var(--danger);
    }

    &.top-2 {
      color: var(--primary);
    }
  }

  .avatar {
    object-fit: cover;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    @media (max-width: 1366px) {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    @media (max-width: 576px) {
      margin-right: 10px;
      width: 36px;
      height: 36px;
    }
  }

  .content {
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 1366px) {
      font-size: 12px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .point {
    font-weight: 500;
    color: var(--danger);
    font-size: 16px;
    padding-right: 20px;
    @media (max-width: 1366px) {
      font-size: 13px;
      padding-right: 10px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      padding-right: 10px;
    }
  }

  &.home {
    .number {
      min-width: 40px;
      font-size: 16px;
      padding-left: 15px;
      @media (max-width: 1366px) {
        min-width: 20px;
        font-size: 13px;
        padding-left: 8px;
      }
      @media (max-width: 576px) {
        min-width: 30px;
        font-size: 14px;
        padding-left: 10px;
      }

      &.top-0 {
        color: #FCB431;
      }

      &.top-1 {
        color: var(--danger);
      }

      &.top-2 {
        color: var(--primary);
      }
    }

    .avatar {
      margin-right: 15px;
      width: 40px;
      height: 40px;
      object-fit: cover;
      @media (max-width: 1366px) {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }
      @media (max-width: 576px) {
        margin-right: 10px;
        width: 36px;
        height: 36px;
      }
    }

    .content {
      font-weight: 500;
      font-size: 16px;
      @media (max-width: 1366px) {
        font-size: 12px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    .point {
      font-weight: 500;
      color: var(--danger);
      font-size: 16px;
      padding-right: 20px;
      @media (max-width: 1366px) {
        font-size: 13px;
        padding-right: 10px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
        padding-right: 10px;
      }
    }
  }
}
</style>
