<template>
  <b-dropdown class="dropdown-select-class" ref="dropdown-select-class" menu-class="menu-class scrollbar mt-0" no-caret toggle-class="text-left custom-caret custom-select text-truncate w-100" variant="transparent">
    <template #button-content>
      {{itemActive ? itemActive.name : placeholder}}
    </template>
    <b-dropdown-text @click="setItemActive({ name: 'Tất cả', id: '' })">Tất cả</b-dropdown-text>
    <b-dropdown-text v-for="item in listData" :key="item.id" @click="setItemActive(item)">{{item.name}}</b-dropdown-text>
  </b-dropdown>
</template>

<script>
import Resource from '~/common/api/resource'
const examGroupResource = new Resource('library/set-exam')
export default {
  props: {
    placeholder: {
      type: String,
      default () {
        return 'Tất cả'
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedId: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      listData: [],
      itemActive: null
    }
  },
  watch: {
    selectedId () {
      this.itemActive = this.listData.find(it => it.id === this.selectedId)
    }
  },
  async created () {
    await this.loadData()
    this.itemActive = this.listData.find(it => it.id === this.selectedId)
  },
  methods: {
    setItemActive (item) {
      this.$refs['dropdown-select-class'].hide(true)
      this.itemActive = item
      this.$emit('findExamGroup', item?.id || null)
    },
    async loadData () {
      if (this.items.length) {
        this.listData = this.items
        return
      }
      const params = {}
      this.listData = await examGroupResource.list(params)
        .then(res => res.data)
    }
  }
}
</script>
