<template>
  <div class="pagination-custom" v-if="total > limit">
    <b-pagination class="pagination-mlearn align-items-center" ellipsis-class="page ellipsis" first-class="first" prev-class="prev" next-class="next" last-class="last" page-class="page" v-model="page" :total-rows="total" :per-page="limit" align="center" :hide-ellipsis="ellipsis" :limit="showLimit">
      <template #first-text>
        <span v-html="require('~/assets/mlearn/icons/pagination/pagination-first.svg?raw')"></span>
      </template>
      <template #prev-text>
        <span v-html="require('~/assets/mlearn/icons/pagination/pagination-prev.svg?raw')"></span>
      </template>
      <template #next-text>
        <span v-html="require('~/assets/mlearn/icons/pagination/pagination-next.svg?raw')"></span>
      </template>
      <template #last-text>
        <span v-html="require('~/assets/mlearn/icons/pagination/pagination-last.svg?raw')"></span>
      </template>
    </b-pagination>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 12
    },
    showLimit: {
      type: Number,
      default: 5
    },
    ellipsis: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    page: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  }
}
</script>
