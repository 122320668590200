<template>
  <b-modal id="modal-delete-playlist" v-model="modalDelete" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc >
    <div v-if="title" class="title" v-html="title"></div>
    <div v-if="message" class="message" v-html="message"></div>
    <div v-if="confirm" class="confirm" v-html="confirm"></div>
    <div class="action text-right">
      <button class="cancel" @click="hide">{{ textCancel }}</button>
      <button class="accpet" @click="accept">{{textAccept}}</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Xóa danh sách đã lưu',
      required: false
    },
    // message: {
    //   type: String,
    //   default: 'Hành động này sẽ xóa tất cả video đã lưu trong <span class="text-break">{{item.name}}</span>',
    //   required: false
    // },
    confirm: {
      type: String,
      default: 'Bạn vẫn muốn xóa ?',
      required: false
    },
    textCancel: {
      type: String,
      default: 'Hủy',
      required: false
    },
    textAccept: {
      type: String,
      default: 'Đồng ý',
      required: false
    }
  },
  data () {
    return {
      modalDelete: false
    }
  },
  computed: {
    message () {
      return this.$store.state.storeFlag?.message || ''
    },
    itemId () {
      return this.$store.state.storeFlag?.id || ''
    }
  },
  methods: {
    hide () {
      this.modalDelete = false
      this.$store.dispatch('setStoreFlag', null)
    },
    accept () {
      this.modalDelete = false
      this.$store.dispatch('setStoreFlag', { id: this.itemId, action: 'accept' })
    }
  }
}
</script>

<style lang="scss">
  #modal-delete-playlist{
    &___BV_modal_outer_{
      z-index: 9999 !important;
    }
    .modal-dialog{
      max-width: 420px;
      .modal-content{
        .modal-body{
          font-size: 16px;
          padding:24px 24px 16px;
          @media (max-width:576px) {
            font-size: 14px;
            padding:16px;
          }
          .title{
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 10px;
            @media (max-width:576px) {
              font-size: 16px;
              margin-bottom: 5px;
            }
          }
          .message{
            span{
              font-weight: bold;
            }
          }
          .confirm{
            padding-top: 16px;
            @media (max-width:576px) {
              padding-top: 10px;
            }
          }
          .action{
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px solid #EBEBEB;
            @media (max-width:576px) {}
            button{
              border-radius: 8px;
              text-align: center;
              width: 120px;
              font-size: 16px;
              padding:10px 0;
              @media (max-width:576px) {
                width: 120px;
                font-size: 14px;
                padding:8px 0;
              }
            }
            .cancel{
              background: transparent;
              color: #868789;
            }
            .accpet{
              background: var(--primary);
              color: var(--white);
            }
          }
        }
      }
    }
  }
</style>
