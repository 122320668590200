<template>
  <div class="question-item-me">
    <div class="block-show">
      <div class="question-title" @click.prevent="redirectDetail">
        {{ item.class.name }} &#8226; {{ item.category.name }} <span class="ml-3 time-ago">{{ item.created_at|ralativeTime }}</span>
      </div>
      <div class="question-content text-justify">
        <span class="pointer" v-if="showMore" @click.prevent="redirectDetail">
          <vue-mathjax :formula="item.content" :safe="false"></vue-mathjax>
        </span>
        <span class="pointer" v-else @click.prevent="redirectDetail">
          <vue-mathjax :formula="item.is_formula ? item.content : $helper.getSubString(item.content, limit)" :safe="false"></vue-mathjax>
        </span>
        <span class="content-more pointer" v-if="!showMore && !item.is_formula" @click.prevent="showMore = true">Xem thêm</span>
      </div>
      <div v-if="item.image && item.image.length" class="question-image" @click.prevent="redirectDetail">
        <div class="image-container text-center">
          <img v-for="img in item.image" :src="img" :key="img" class="pointer">
        </div>
      </div>
      <template v-if="item.answer">
        <div class="line"></div>
        <div class="user-info">
          <div class="title-answer font-weight-bold">Trả lời ({{ item.total_answer }})</div>
          <div class="user-answer form-data">
            <img :src="item.answer.owner.avatar || NoAvatar" class="avatar rounded-circle mr-2 mr-lg-3"/>
            <div class="content">
              <div class="user-name font-weight-bold text-break">{{ item.answer.owner.fullName }}</div>
              <div class="posted-time">{{ item.answer.created_at | ralativeTime }}</div>
            </div>
          </div>
          <div v-if="item.answer.content" class="user-answer-content">
            <vue-mathjax :formula="item.answer.content" :safe="false"></vue-mathjax>
          </div>
          <template v-if="item.answer.image && item.answer.image.length">
            <div class="image-container text-center bg-white">
              <img v-for="img in item.answer.image" :src="img" :key="img">
            </div>
          </template>
          <div v-if="item.total_answer > 1">
            <nuxt-link :to="'/hoi-bai-cua-toi/'+item.id" class="view-more-answer text-justify">Xem thêm trả lời</nuxt-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      showMore: true,
      limit: 500,
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  created () {
    if (this.item.content.length > this.limit) {
      this.showMore = false
    }
  },
  methods: {
    redirectDetail () {
      this.$router.push('/hoi-bai-cua-toi/' + this.item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.question-item-me {
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 24px;
  @media (max-width: 576px) {
    margin-bottom: 12px;
    padding: 16px;
  }

  .block-show {
    .question-title {
      font-weight: 500;
      font-size: 14px;
      color: var(--primary);

      .time-ago {
        font-size: 12px;
        color: #65676B;
      }
    }

    .question-content {
      font-size: 16px;
      padding-top: 10px;
      @media (max-width: 576px) {
        padding-top: 5px;
      }

      .content-more {
        color: var(--primary);
        z-index: 99;
      }
    }

    .question-image {
      margin-top: 15px;
      @media (max-width: 576px) {
        margin-top: 10px;
      }

      .image-container {
        border: 1px solid #EBEBEB;
        border-radius: 8px;
        margin-bottom: 16px;
        @media (max-width: 576px) {
          margin-bottom: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        img {
          max-height: 300px;
          @media (max-width: 576px) {
            max-height: 200px;
          }
        }
      }
    }

    .user-info {
      padding-top: 30px;
      padding-left: 20px;
      @media (max-width: 576px) {
        padding-top: 20px;
        padding-left: 10px;
      }

      .title-answer {
        font-size: 16px;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      .user-answer {
        padding-top: 16px;
        @media (max-width: 576px) {
          padding-top: 12px;
        }

        img {
          width: 46px;
          height: 46px;
          @media (max-width: 576px) {
            width: 36px;
            height: 36px;
          }
        }

        .user-name {
          margin-bottom: 5px;
          font-size: 16px;
          @media (max-width: 576px) {
            margin-bottom: 0px;
            font-size: 14px;
          }
        }

        .posted-time {
          font-size: 12px;
          color: #65676B;
        }
      }

      .user-answer-content {
        padding-top: 10px;
        @media (max-width: 576px) {
          padding-top: 5px;
        }
      }

      .image-container {
        border: 1px solid #EBEBEB;
        border-radius: 4px;
        margin-top: 10px;
        padding: 10px;
        @media (max-width: 576px) {
          margin-top: 5px;
          padding: 5px;
        }
      }

      .view-more-answer {
        font-weight: 500;
        padding-top: 10px;
        font-size: 13px;
        color: #6B6D70;
        @media (max-width: 576px) {
          padding-top: 8px;
          font-size: 12px;
          color: var(--primary);
        }
      }
    }
  }
}
</style>
