<template>
  <div class="custom-radio">
    <div class="custom-radio-check" v-for="item in listShow" :key="item.id" @click="setItemActive(item)" :class="{'active':itemActive ? item.id === itemActive.id : false}">{{ item.name }}</div>
    <!--    <div class="custom-radio-check extend" @click="extend()">...</div>-->
    <!--&lt;!&ndash;  </div>&ndash;&gt;-->
    <!--&lt;!&ndash;  <div class="custom-radio" v-else>&ndash;&gt;-->
    <!--&lt;!&ndash;    <div class="custom-radio-check" v-for="item in listData" :key="item.id" @click="setItemActive(item)" :class="{'active':itemActive ? item.id === itemActive.id : false}">{{ item.name }}</div>&ndash;&gt;-->
    <!--&lt;!&ndash;  </div>&ndash;&gt;-->
    <b-dropdown class="custom-radio-check extend" id="custom-radio-dropdown" :class="{'home-select': home}" ref="custom-radio-check" menu-class="menu-class scrollbar mt-0" no-caret :toggle-class="home ? 'text-left home-custom-caret home-custom-select text-truncate w-100' : 'text-left custom-caret custom-select text-truncate w-100'" variant="transparent">
      <template #button-content>
        {{ placeholder }}
      </template>
      <b-dropdown-text v-for="item in listExtend" :key="item.id" @click="setItemActive(item)">{{ item.name }}</b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'

const subjectResource = new Resource('category/subject')
export default {
  props: {
    all: {
      type: Boolean,
      default: true
    },
    require: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default () {
        return '...'
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedId: {
      type: String,
      default () {
        return ''
      }
    },
    juniorClass: {
      type: Boolean,
      default () {
        return false
      }
    },
    registerGuru: {
      type: Boolean,
      default () {
        return false
      }
    },
    libraryShow: {
      type: Boolean,
      default () {
        return false
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  data () {
    return {
      listData: [],
      listShow: [],
      listExtend: [],
      itemActive: null,
      show: false
    }
  },
  watch: {},
  async created () {
    await this.loadData()
    this.itemActive = this.listData.find(it => it.id === this.selectedId)
    if (this.require && !this.itemActive) {
      this.show = true
    }
  },
  methods: {
    setItemActive (item) {
      this.itemActive = item
      this.splitListData(item)
      this.$emit('findClass', item?.id || null, item)
      this.setStore(item?.id)
    },
    setStore (id) {
      const data = {
        ...this.$store.state.storeRedirect,
        class_id: id
      }
      this.$store.dispatch('setStoreRedirect', data)
    },
    splitListData (item) {
      if (item) {
        if (!this.listShow.some(it => it.id === item.id)) {
          const lastShowItem = this.listShow.pop()
          this.listShow.push(item)
          this.listExtend = [...this.listExtend].filter(it => it.id !== item.id)
          this.listExtend.push(lastShowItem)
        }
      } else if (this.isMobile) {
        this.listShow = [...this.listData].filter(it => it.sort_order < 4)
        this.listExtend = [...this.listData].filter(it => it.sort_order >= 4)
      } else {
        this.listShow = [...this.listData].filter(it => it.sort_order < 6)
        this.listExtend = [...this.listData].filter(it => it.sort_order >= 6)
      }
    },
    async loadData () {
      if (this.items.length) {
        this.listData = this.items
        return
      }
      const params = {}
      if (this.registerGuru) {
        params.register_guru = 1
      }
      if (this.libraryShow) {
        params.library_show = 1
      }
      this.listData = await subjectResource.list(params)
        .then(res => res.data)

      this.splitListData()
    }
  }
}
</script>
<style lang="scss">
#custom-radio-dropdown {
  .custom-select {
    border: none;
    padding: 0 10px 5px;
    height: unset;
    font-size: 14px;
    line-height: 10px;
    text-align: center;
    background: none;
  }
}
</style>
<style lang="scss" scoped>
.custom-radio {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 7px;
  margin-bottom: 10px;

  .custom-radio-check {
    height: 22px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    padding: 0px 5px;

    &.active {
      background: var(--primary);
      color: var(--white);
    }
  }

  .dropdown-toggle {
    font-weight: 500;
    border: 1px solid #CED4DA !important;
    border-radius: 8px;
    box-shadow: none !important;
    padding: 10px 30px 10px 12px;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .require {
      color: var(--danger);
    }
  }

  ul {
    padding: 0;
    max-height: 300px;
    min-width: 100%;

    li {
      > .b-dropdown-text, > .dropdown-header {
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;
        padding: 10px 16px;
        @media (max-width: 1366px) {
          padding: 8px 10px;
        }
      }

      .dropdown-header {
        // font-weight: bold;
        + ul {
          padding-left: 20px;
        }
      }

      .b-dropdown-text {
        &:hover {
          background: transparent;
          color: var(--primary)
        }
      }

      &:last-child {
        .b-dropdown-text {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
