<template>
  <div class="ml-playlist-video">
    <ViewMode :title="tab.name" @mode="changeMode"/>
    <div class="line"></div>
    <div :class="{'bg-white px-3': !viewList}">
      <div class="row list-item mb-4">
        <VideoTopicItem v-for="item in listData" :key="item.id" :class="{'topic-item-list col-sm-6 col-md-4 col-xl-3': viewList, 'topic-item-detail col-sm-12': !viewList}" :view-list="viewList" :item="item" @showNotify="showNotify"/>
      </div>
      <NoData v-if="!listData.length && !loading" text="Nội dung đang cập nhật"/>
    </div>
    <Pagination :total="query.total" :value="query.page" @change="loadData" :limit="query.limit"/>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false"/>
  </div>
</template>

<script>
// import cookieParser from 'cookieparser'
import VideoTopicItem from '~/components/mlearn/video/topic.vue'
import Pagination from '~/components/mlearn/pagination/index'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Resource from '~/common/api/resource'
import ViewMode from '~/components/mlearn/view-mode/index.vue'

const topicResource = new Resource('library/topic/list-learning')
const playlistDetailResource = new Resource('library/playlist/getBySlug')
const followResource = new Resource('library/topic/listFollow')
export default {
  components: {
    VideoTopicItem,
    Pagination,
    PlaylistNotify,
    ViewMode
  },
  props: {
    tab: {
      type: Object,
      default: null,
      required: false
    }
  },
  // async asyncData ({
  //   isDev,
  //   route,
  //   store,
  //   env,
  //   params,
  //   req,
  //   res,
  //   redirect,
  //   error
  // }) {
  //   let token = ''
  //   if (req && req.headers && req.headers.cookie) {
  //     const requestCookies = cookieParser.parse(req.headers.cookie)
  //     token = requestCookies.access_token || ''
  //   }
  //   const query = {
  //     category_id: '',
  //     class_id: '',
  //     type: 'video',
  //     keyword: '',
  //     page: 1,
  //     limit: 12,
  //     total: 0
  //   }
  //   const storeData = store.state.storeRedirect
  //   if (storeData) {
  //     query.category_id = storeData.category_id || ''
  //     query.class_id = storeData.class_id || ''
  //   }
  //   const topics = await topicResource.list(query, token)
  //   query.total = topics.total || 0
  //
  //   return {
  //     listData: topics.data,
  //     query,
  //     viewList: true,
  //     flagNotify: false,
  //     textNotify: '',
  //     loading: false
  //   }
  // },
  data () {
    return {
      carouselSettings: {
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      },
      listData: [],
      query: {
        category_id: '',
        class_id: '',
        type: 'video',
        keyword: '',
        page: 1,
        limit: 12,
        total: 0,
        slug: ''
      },
      viewList: true,
      flagNotify: false,
      textNotify: '',
      loading: false
    }
  },
  watch: {
    tab () {
      this.query.page = 1
      this.loadData()
    }
  },
  created () {
    this.$store.dispatch('setStoreRedirect', null)
    this.loadData()
  },

  methods: {
    changeMode (mode) {
      this.viewList = mode
    },
    async loadData (page = 1) {
      this.query.page = page
      if (this.loading) {
        return
      }
      this.loading = true
      let data = null
      if (!this.tab || this.tab.to === 'dang-hoc') {
        data = await topicResource.list(this.query)
        this.listData = data.data || []
      } else if (this.tab.to === 'theo-doi') {
        const query = {
          limit: 12,
          page: this.query.page,
          total: 0
        }
        data = await followResource.list(query)
        this.listData = data.data || []
      } else {
        const query = {
          limit: 12,
          page: this.query.page,
          total: 0,
          slug: this.tab.to
        }
        data = await playlistDetailResource.list(query)
        this.listData = data.data?.items || []
      }

      this.query.total = data.total || 0
      this.loading = false
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-playlist-video {
  .line {
    border-bottom: 1px solid #EBEBEB;
    margin: 10px 0;
  }

  .list-item {
    .topic-item-list {
      padding-top: 18px;
    }

    .topic-item-detail {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #EBEBEB;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
