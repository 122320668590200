<template>
  <div class="custom-radio">
    <div class="custom-radio-check" v-for="item in listData" :key="item.id" @click="setItemActive(item)" :class="{'active':itemActive ? item.id === itemActive.id : false}">{{ formatClassNum(item.name) }}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
// import Resource from '~/common/api/resource'

// const classResource = new Resource('category/class')
export default {
  props: {
    all: {
      type: Boolean,
      default: true,
      required: false
    },
    require: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default () {
        return 'Chọn lớp'
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedId: {
      type: String,
      default () {
        return ''
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      listData: [],
      itemActive: null
    }
  },
  computed: {
    ...mapState([
      'storeRedirect'
    ])
  },
  watch: {
    selectedId () {
      this.itemActive = this.listData.find(it => it.id === this.selectedId)
      if (!this.itemActive) {
        this.itemActive = {
          name: 'Tất cả',
          id: ''
        }
      }
    }
  },
  created () {
    this.loadData()
    this.itemActive = this.listData.find(it => it.id === this.selectedId)
    this.setStore(this.selectedId)
  },
  methods: {
    formatClassNum (classStr) {
      const firstDigit = classStr.match(/\d/) // will give you the first digit in the string
      const indexed = classStr.indexOf(firstDigit)
      return classStr.substring(indexed)
    },
    setItemActive (item) {
      this.itemActive = item
      let isJuniorClass = false
      if (['Lớp 6', 'Lớp 7', 'Lớp 8', 'Lớp 9'].includes(item.name)) {
        isJuniorClass = true
      }
      this.$emit('findClass', item?.id || null, item, isJuniorClass)
      // this.setStore(item?.id)
    },
    setStore (id) {
      const data = {
        ...this.$store.state.storeRedirect,
        class_id: id
      }
      this.$store.dispatch('setStoreRedirect', data)
    },
    loadData () {
      if (this.items.length) {
        this.listData = this.items
        return
      }
      // const params = {}
      this.listData = this.$store.getters['context/classes']
      // this.listData = await classResource.list(params)
      //   .then(res => res.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-radio {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 7px;
  margin-bottom: 10px;

  .custom-radio-check {
    width: 37px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;

    &.active {
      background: var(--primary);
      color: var(--white);
    }
  }
}
</style>
