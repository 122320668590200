<template>
  <div class="ml-playlist-quizz">
    <ViewMode :title="tab.name" @mode="changeMode"/>
    <div class="line"></div>
    <div :class="{'bg-white px-3': !viewList}">
      <div class="row list-data">
        <QuizItem v-for="(item) in listData" :key="item.id" :class="viewList ? 'col-sm-6 col-md-4 col-xl-3 pt-2' : 'col-12 py-2'" :item="item" :view-list="viewList" :playlist="playlist" @createdPlaylist="loadPlayList()" @showNotify="showNotify"/>
      </div>
      <NoData v-if="!listData.length && !loading"/>
    </div>
    <Pagination class="mt-3" :total="query.total" :value="query.page" @change="loadData" :limit="query.limit"/>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false"/>
  </div>
</template>

<script>
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import Pagination from '~/components/mlearn/pagination/index.vue'
import QuizItem from '~/components/mlearn/quiz/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Resource from '~/common/api/resource'

const playlistResource = new Resource('library/playlist/all')
const myExamResource = new Resource('library/exam/myExam')
const playlistDetailResource = new Resource('library/playlist/exam/getBySlug')
export default {
  components: {
    ViewMode,
    Pagination,
    QuizItem,
    PlaylistNotify
  },
  props: {
    tab: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      viewList: true,
      query: {
        limit: 12,
        page: 1,
        total: 0,
        state: 'ENDED'
      },
      playlist: [],
      listData: [],
      textNotify: '',
      flagNotify: false
    }
  },
  watch: {
    tab () {
      this.query.page = 1
      this.loadData()
      this.loadPlaylist()
    }
  },
  created () {
    this.loadData()
    this.loadPlaylist()
  },
  methods: {
    async loadData (page = 1) {
      this.query.page = page
      if (this.loading) {
        return
      }
      this.loading = true
      let data = null
      if (!this.tab || this.tab.to === 'da-lam') {
        const query = {
          limit: 12,
          page: this.query.page,
          total: 0,
          state: 'ENDED'
        }
        data = await myExamResource.list(query)
        this.listData = data.data || []
      } else if (this.tab.to === 'tam-dung') {
        const query = {
          limit: 12,
          page: this.query.page,
          total: 0,
          state: 'PAUSED'
        }
        data = await myExamResource.list(query)
        this.listData = data.data || []
      } else {
        const query = {
          limit: 12,
          page: this.query.page,
          total: 0,
          slug: this.tab.to
        }
        data = await playlistDetailResource.list(query)
        this.listData = data.data?.items || []
      }

      this.query.total = data.total
      this.loading = false
    },
    changeMode (mode) {
      this.viewList = mode
    },
    async loadPlaylist () {
      const {data} = await playlistResource.list({type: 'exam'})
      this.playlist = data
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-trac-nghiem-da-lam {
  .line {
    border-bottom: 1px solid #EBEBEB;
    margin: 10px 0;
  }

  .list-data {
    .col-12 {
      border-bottom: 1px solid #EBEBEB;
    }
  }
}
</style>
