<template>
  <div v-if="item">
    <!--    <div class="ml-playlist-item d-none d-lg-block">-->
    <!--      <div class="title pointer" @click="redirectView">{{item.name}}</div>-->
    <!--      <div class="line"></div>-->
    <!--      <div class="row action align-items-center">-->
    <!--        <div class="col-7">-->
    <!--          <span v-if="number" class="number pointer" @click="redirectView">{{ number }} files</span>-->
    <!--        </div>-->
    <!--        <div class="col-5 text-right" v-if="action">-->
    <!--          <img src="~/mlearn/icons/library/delete.svg" alt="delete" class="action pointer" @click="deleteAction">-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="ml-playlist-item-mobile" :class="{'active': isActive}">
      <div class="form-data">
        <div class="content title" @click="redirectView">
          {{ item.name }} <span v-if="number" class="number pointer font-weight-normal ml-2">{{ number }}</span>
        </div>
        <div class="action" v-if="action">
          <img src="~/mlearn/icons/library/delete.svg" alt="delete" class="action pointer" @click="deleteAction">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'

const playlistResource = new Resource('library/playlist')
export default {
  props: {
    action: {
      type: Boolean,
      default: true,
      required: false
    },
    isActive: {
      type: Boolean,
      default: true,
      required: false
    },
    type: {
      type: String,
      default: '',
      required: false
    },
    to: {
      type: String,
      default: '',
      required: false
    },
    item: {
      type: Object,
      default: null,
      required: false
    }
  },
  computed: {
    storeFlag () {
      return this.$store.state.storeFlag
    },
    number () {
      return this.item.number || this.item.number_video || this.item.number_file || this.item.number_exam || 0
    }
  },
  watch: {
    storeFlag () {
      if (!this.storeFlag) {
        return
      }
      if (this.storeFlag.action && this.storeFlag.action === 'accept' && this.storeFlag.id === (this.item?.id || '')) {
        this.acceptDelete()
      }
    }
  },
  methods: {
    deleteAction () {
      if (!this.action) {
        this.$notify({
          type: 'warning',
          message: 'Chức năng xóa không được hỗ trợ'
        })
        return
      }
      let source = ''
      switch (this.type) {
        case 'video':
          source = 'video'
          break
        case 'document':
          source = 'tài liệu'
          break
        case 'quiz':
          source = 'bộ đề'
          break
        default:
          source = 'video'
          break
      }
      const message = `Hành động này sẽ xóa tất cả ${source} đã lưu trong <b>"${this.item?.name || 'Tên playlist'}"</b>`
      this.$store.dispatch('setStoreFlag', {
        id: this.item?.id || '',
        message,
        action: 'confirm'
      })
      this.$bvModal.show('modal-delete-playlist')
    },
    acceptDelete () {
      const itemId = this.storeFlag.id
      if (!itemId) {
        this.$notify({
          type: 'warning',
          message: 'Có lỗi xảy ra khi xóa dữ liệu'
        })
        return
      }
      playlistResource.destroy(itemId)
        .then((res) => {
          let source = ''
          switch (this.type) {
            case 'video':
              source = 'video'
              break
            case 'document':
              source = 'tài liệu'
              break
            case 'quiz':
              source = 'bộ đề'
              break
            default:
              source = 'video'
              break
          }
          const message = `Đã xóa tất cả ${source} đã lưu trong <b>"${this.item?.name || 'Tên playlist'}"</b>`
          this.$notify({
            type: 'success',
            dangerouslyUseHTMLString: true,
            message
          })
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            message: error.response.data.message
          })
        })
        .finally(() => {
          this.$emit('deleted')
        })
    },
    redirectView () {
      this.$emit('activeTab', {
        name: this.item.name,
        to: this.to
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-playlist-item {
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
  border-radius: 8px;
  padding: 16px;
  min-height: 121px;
  @media (max-width: 576px) {
    padding: 10px;
    min-height: 80px;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .line {
    border-top: 1px solid #EBEBEB;
    margin: 20px 0;
    @media (max-width: 576px) {
      margin: 10px 0;
    }
  }

  .action {
    .number {
      font-size: 14px;
      color: #868789;
    }
  }
}

.ml-playlist-item-mobile {
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
  border-radius: 8px;
  padding: 16px;
  @media (max-width: 576px) {
    padding: 12px 12px 16px 16px;
  }

  &.active {
    .title {
      color: var(--primary);
    }
  }

  .form-data {
    .content {
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      @media (max-width: 576px) {
        font-size: 14px;
      }

      .number {
        font-size: 12px;
        color: #868789;
      }
    }
  }
}
</style>
