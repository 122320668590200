<template>
  <div class="ml-playlist-document">
    <ViewMode :title="tab.name" @mode="changeMode"/>
    <div class="line"></div>
    <div :class="{'bg-white px-3': !viewList}">
      <div class="row list-data">
        <DocumentItem :class="viewList ? 'col-sm-6 col-md-4 col-xl-3 pt-2' : 'col-12 py-2'" :view-list="viewList" v-for="(item) in listData" :key="item.id" :item="item" :playlist="playlist" @createdPlaylist="loadPlaylist()" @showNotify="showNotify"/>
      </div>
      <NoData v-if="!listData.length && !loading"/>
    </div>
    <Pagination class="mt-3" :total="query.total" :value="query.page" @change="loadData" :limit="query.limit"/>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false"/>
  </div>
</template>

<script>
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import DocumentItem from '~/components/mlearn/document/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Pagination from '~/components/mlearn/pagination/index'
import Resource from '~/common/api/resource'

const fileResource = new Resource('library/file/viewed')
const playlistResource = new Resource('library/playlist/all')
const playlistDetailResource = new Resource('library/playlist/getBySlug')
export default {
  components: {
    DocumentItem,
    PlaylistNotify,
    Pagination,
    ViewMode
  },
  props: {
    tab: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    const viewList = true
    return {
      viewList,
      playlist: [],
      listData: [],
      query: {
        limit: 12,
        page: 1,
        total: 0
      },
      loading: false,
      flagNotify: false,
      textNotify: ''
    }
  },
  watch: {
    tab () {
      this.query.page = 1
      this.loadData()
      this.loadPlaylist()
    }
  },
  created () {
    this.loadData()
    this.loadPlaylist()
  },

  methods: {
    async loadData (page = 1) {
      this.query.page = page
      if (this.loading) {
        return
      }
      this.loading = true
      let data = null
      if (!this.tab || this.tab.to === 'da-xem') {
        data = await fileResource.list(this.query)
        this.listData = data.data || []
      } else {
        const query = {
          limit: 12,
          page: this.query.page,
          total: 0,
          slug: this.tab.to
        }
        data = await playlistDetailResource.list(query)
        this.listData = data.data?.items || []
      }

      this.query.total = data.total
      this.loading = false
    },
    redirectLink (link) {
      this.$router.push('/thu-vien-cua-toi/' + link)
    },
    changeMode (mode) {
      this.viewList = mode
    },
    async loadPlaylist () {
      const {data} = await playlistResource.list({type: 'doc'})
      this.playlist = data
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-tai-lieu-da-xem {
  .line {
    border-bottom: 1px solid #EBEBEB;
    margin: 10px 0;
    @media (max-width: 576px) {
      margin: 8px 0;
    }
  }

  .list-data {
    .col-12 {
      border-bottom: 1px solid #EBEBEB;

      &:first-child {
        padding-top: 24px !important;
        @media (max-width: 576px) {
          padding-top: 16px !important;
        }
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 24px !important;
        @media (max-width: 576px) {
          padding-bottom: 16px !important;
        }
      }
    }
  }
}
</style>
