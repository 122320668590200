<template>
  <b-dropdown class="dropdown-select-class" ref="dropdown-select-class" menu-class="menu-class scrollbar mt-0" no-caret toggle-class="text-left custom-caret custom-select text-truncate w-100" variant="transparent">
    <template #button-content>
      {{ checkItemActive(itemActive)}} <span v-if="show" class="require">*</span>
    </template>
    <b-dropdown-text v-for="(item, ind) in listData" :key="ind" @click="setItemActive(item)" :class="{'active': item === itemActive}">{{item.label || item}}</b-dropdown-text>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    require: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default () {
        return 'Chọn môn'
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedValue: {
      type: [String, Number],
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      listData: [],
      itemActive: null,
      show: false
    }
  },
  watch: {
    selectedValue () {
      this.getItemActive()
    }
  },
  created () {
    this.listData = this.items
    this.getItemActive()
  },
  methods: {
    checkItemActive (item) {
      const type = typeof item
      if (type === 'object') {
        return (item.label || this.placeholder)
      } else if (type === 'string') {
        return item
      } else {
        return this.placeholder
      }
    },
    getItemActive () {
      this.itemActive = this.listData.find((it) => {
        const type = typeof it.value
        if (['string', 'number'].includes(type)) {
          return it.value === this.selectedValue
        } else {
          return it === this.selectedValue
        }
      })
      if (this.require && !this.itemActive) {
        this.show = true
      }
    },
    setItemActive (item) {
      this.$refs['dropdown-select-class'].hide(true)
      this.itemActive = item
      const type = typeof item
      this.show = true
      if (type === 'object') {
        if (typeof item.value === 'number' || typeof item.value === 'string') {
          this.show = false
          this.$emit('select', item.value)
        } else {
          this.$emit('select', '')
        }
      } else {
        this.$emit('select', item)
      }
    }
  }
}
</script>
