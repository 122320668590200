<template>
  <div class="ml-quiz-guide vh-100">
    <div class="guide-title text-center text-uppercase font-weight-bold">
      Hướng dẫn làm bài
    </div>
    <div class="guide-item form-data">
      <div class="guide-item-left text-center">
        <b>3</b>/20
      </div>
      <div class="content">
        Câu hỏi đang xem trên tổng số câu hỏi trong đề
      </div>
    </div>
    <div class="guide-item form-data">
      <div class="guide-item-left text-center">
        <img src="~/mlearn/icons/quiz/guide/answer.svg" alt="">
      </div>
      <div class="content">
        Chọn đáp án đúng
      </div>
    </div>
    <div class="guide-item form-data">
      <div class="guide-item-left text-center">
        <img src="~/mlearn/icons/quiz/guide/left.svg" alt="">
      </div>
      <div class="content">
        Chuyển sang câu hỏi tiếp theo hoặc vuốt màn hình sang trái
      </div>
    </div>
    <div class="guide-item form-data">
      <div class="guide-item-left text-center">
        <img src="~/mlearn/icons/quiz/guide/right.svg" alt="">
      </div>
      <div class="content">
        Trở về câu hỏi trước đó hoặc vuốt màn hình sang phải
      </div>
    </div>
    <div class="guide-item form-data">
      <div class="guide-item-left text-center">
        <img src="~/mlearn/icons/quiz/guide/check.svg" alt="">
      </div>
      <div class="content">
        Đánh dấu để xem lại câu hỏi trước khi nộp bài nếu chưa chắc chắn câu trả lời
      </div>
    </div>
    <div class="guide-note">
      <div class="guide-note-title font-weight-bold">Lưu ý</div>
      <div class="guide-note-item d-flex align-items-center">
        <div class="note-item-right">&#8226; Những <strong>câu hỏi chưa chọn đáp án</strong> sẽ được tính là câu trả lời sai</div>
      </div>
      <div class="guide-note-item d-flex align-items-center">
        <div class="note-item-right">&#8226; Nếu thoát ra khi đang làm bài, kết quả sẽ không được tính</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'empty'
}
</script>
<style lang="scss" scoped>
  .ml-quiz-guide{
    background: #F3F6FB;
    padding: 24px;
    @media (max-width:576px) {
      padding: 16px;
    }
    .guide-title {
      font-size: 16px;
      color: #F4B556;
      margin-bottom: 20px;
      @media (max-width:576px) {
        margin-bottom: 16px;
      }
    }
    .guide-item {
      margin-bottom: 20px;
      font-size: 16px;
      @media (max-width:576px) {
        margin-bottom: 16px;
      }
      .guide-item-left {
        min-width: 45px;
        @media (max-width:576px) {}
      }
    }
    .guide-note {
      padding-left: 5px;
      .guide-note-title {
        font-size: 16px;
        text-decoration-line: underline;
        color: var(--danger);
        margin-bottom: 20px;
        @media (max-width:576px) {
          margin-bottom: 16px;
        }
      }
      .guide-note-item {
        font-size: 16px;
        margin-bottom: 16px;
        @media (max-width:576px) {
          margin-bottom: 10px;
        }
        .note-item-right {
          margin-left: 16px;
          @media (max-width:576px) {
            margin-left: 0;
          }
        }
      }
    }
  }
</style>
