<template>
  <div class="question-item" v-if="item">
    <span class="question-guru-is-awnser" v-if="item.is_guru_answer === 1">Gia sư giải bài</span>
    <div class="user-info form-data">
      <!-- <img :src="item.owner.avatar || NoAvatar" class="avatar" @error="setAvatar" /> -->
      <b-avatar :src="item.owner.avatar || NoAvatar" class="avatar"></b-avatar>
      <div class="content">
        <div class="user-name text-break">{{ hidePhoneNumber(item.owner.fullName) }}</div>
        <div class="time-ago">{{ item.created_at|ralativeTime }}</div>
        <div class="class-info" @click.prevent="redirectDetail">{{ item.class.name }} &#8226; {{ item.category.name }}</div>
      </div>
    </div>
    <div class="question-content pointer" @click.prevent="redirectDetail">
      <vue-mathjax v-if="showMore" :formula="item.content" :safe="false"></vue-mathjax>
      <vue-mathjax v-else :formula="item.is_formula ? item.content : $helper.getSubString(item.content, limit)" :safe="false"></vue-mathjax>
      <span class="content-more pointer" v-if="!showMore && !item.is_formula" @click.prevent="showMore = true">Xem thêm</span>
    </div>
    <div v-if="item.image && item.image.length" class="question-image" @click.prevent="redirectDetail">
      <div class="image-container text-center">
        <img v-for="img in item.image" :src="img" :key="img" alt="Image">
      </div>
    </div>
    <div v-else class="line"></div>
    <div class="row align-items-center question-info">
      <div class="col-lg-9">
        <span class="avatar-mask position-relative" v-for="(answering, ind) in listTyping" :key="'answering_'+ind">
          <b-avatar class="avatar" :src="answering.user_avatar || NoAvatar"></b-avatar>
          <img src="~/mlearn/icons/home/typing.gif" alt="" class="typing position-absolute"/>
        </span>
        <span class="avatar-mask pointer" v-if="item.answers && item.answers.length" @click.prevent="redirectDetail">
          <template v-for="(answer_, ind) in item.answers">
            <img :key="ind" v-if="ind < 2" :src="answer_.avatar || NoAvatar" alt="avatar" class=""/>
            <img :key="ind" v-if="ind === 2 && item.answers.length === 3" :src="answer_.avatar || NoAvatar" alt="avatar" class=""/>
            <!-- <b-avatar :key="ind" v-if="ind < 2" :src="answer_.avatar || NoAvatar"></b-avatar> -->
            <!-- <b-avatar :key="ind" v-if="ind === 2 && item.answers.length === 3" :src="answer_.avatar || NoAvatar"></b-avatar> -->
            <span :key="ind" v-if="item.answers.length > 3 && ind === 2" class="answer-more">
              <img :src="item.answers[2].avatar ? item.answers[2].avatar : NoAvatar" alt="avatar" class=""/>
              <!-- <b-avatar :src="item.answers[2].avatar ? item.answers[2].avatar : NoAvatar"></b-avatar> -->
              <span>+{{ item.answers.length - 3 }}</span>
            </span>
          </template>
        </span>
      </div>
      <div class="col-lg-3 text-right number-answer">{{ colearnQuestion ? item.allow_answer : item.total_answer }} câu trả lời</div>
      <div class="col-lg-12 text-right action">
        <button v-if="!colearnQuestion && canAnswer && canTyping" @click.prevent="redirectAnswer" :disabled="!canTyping" class="d-flex align-items-center ml-auto"><img src="~/mlearn/icons/home/answer-question.svg" alt="Trả lời" class="mr-2"> Trả lời</button>
        <button v-else @click.prevent="redirectDetail">Chi tiết</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    colearnQuestion: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showMore: true,
      limit: 500,
      listTyping: [],
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'user',
      'authenticated'
    ]),
    canAnswer () {
      if (!this.item.allow_answer && this.item.answers && this.item.answers.length >= 3) {
        return false
      } // Đã có nhiều hơn 3 câu trả lời và chưa đồng ý thêm
      if (!this.authenticated) {
        return true
      } // Chưa đăng nhập
      if (this.user.uuid === this.item.created_by) {
        return false
      } // Là người đăng câu hỏi
      if (!this.item.answers) {
        return true
      } // Chưa trả lời
      const check = this.item.answers.find(user => user.uuid === this.user.uuid) // check xem trả lời chưa
      return !check
    },
    canTyping () {
      if (!this.item.allow_answer) { // đủ câu trả lời
        return false
      }
      if (this.item.answers) { // có câu trả lời rồi
        if (this.item.answers.length >= 3) { // Đã yêu cầu thêm câu trả lời
          if (this.listTyping.length >= 3) {
            return false
          } else {
            return true
          }
        } else if (this.item.answers.length + this.listTyping.length >= 3) { // chưa yêu cầu thêm câu trả lời
          return false
        } else {
          return true
        }
      } else if (this.listTyping.length >= 3) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    item () {
      if (this.item.content.length > this.limit) {
        this.showMore = false
      } else {
        this.showMore = true
      }
    }
  },
  sockets: {
    TYPING_ANSWER (data) {
      if (this.item.id === data.question_id) {
        if (this.listTyping.length < 3) {
          const check = this.listTyping.find(it => it.user_id === data.user_id)
          if (check) {
            return
          }
          this.listTyping.push(data)
        }
      }
    },
    CANCEL_TYPING_ANSWER (data) {
      if (this.item.id === data.question_id) {
        this.listTyping = this.listTyping.filter(it => it.user_id !== data.user_id)
      }
    },
    ANSWER_SUCCESS (data) {
      if (this.item.id === data.question_id) {
        const owner = data.owner
        this.listTyping = this.listTyping.filter(it => it.user_id !== owner.uuid)
        if (!this.item.answers) {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.answers = []
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.item.answers.unshift(owner)
        // eslint-disable-next-line vue/no-mutating-props
        this.item.total_answer++
      }
    }
  },
  created () {
    if (this.item.content.length > this.limit) {
      this.showMore = false
    }
  },
  methods: {
    setAvatar (event) {
      event.target.src = this.NoAvatar
    },
    redirectDetail () {
      if (this.colearnQuestion) {
        this.$router.push('/cau-hoi/' + this.item.id)
      } else {
        this.$router.push('/cau-hoi-cong-dong/' + this.item.id)
      }
    },
    redirectAnswer () {
      // if (!this.user) {
      //   this.$bvModal.show('modal-login')
      //   return
      // }
      // this.$router.push('/tra-loi/' + this.item.id)
      if (this.colearnQuestion) {
        this.$router.push('/cau-hoi/' + this.item.id)
      } else {
        this.$router.push('/cau-hoi-cong-dong/' + this.item.id)
      }
    },
    hidePhoneNumber (name) {
      if (/^\d+$/.test(name)) {
        return name.substring(0, 6) + '***'
      } else {
        return name
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question-item {
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
  border-radius: 8px;
  margin-bottom: 12px;
  position: relative;
  padding: 24px;
  @media (max-width: 1366px) {
    padding: 18px;
  }
  @media (max-width: 576px) {
    padding: 16px;
  }

  .question-guru-is-awnser {
    font-weight: 500;
    position: absolute;
    right: 18px;
    top: 18px;
    font-size: 15px;
    padding: 5px 5px 5px 25px;
    @media (max-width: 1366px) {
      right: 18px;
      top: 18px;
      font-size: 15px;
      padding: 5px 5px 5px 25px;
    }
    @media (max-width: 576px) {
      right: 16px;
      top: 16px;
      font-size: 10px;
      padding: 3px 3px 3px 20px;
    }

    background: yellow;
    text-align: center;
  }

  .question-guru-is-awnser:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #ffffff;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;

    @media (max-width: 1366px) {
      border-left: 20px solid #ffffff;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
    }
    @media (max-width: 576px) {
      border-left: 15px solid #ffffff;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }

  }

  .question-first {
    font-weight: 500;
    font-size: 13px;
    color: var(--danger);
    position: absolute;
    right: 24px;
    top: 24px;
    @media (max-width: 1366px) {
      right: 18px;
      top: 18px;
    }
    @media (max-width: 576px) {
      right: 16px;
      top: 16px;
    }
  }

  .user-info {
    .avatar {
      margin-right: 12px;
      border-radius: 50%;
      object-fit: cover;
      width: 62px;
      height: 62px;
      @media (max-width: 1366px) {
        width: 50px;
        height: 50px;
      }
      @media (max-width: 576px) {
        width: 48px;
        height: 48px;
      }
    }

    .content {
      .user-name {
        font-weight: bold;
        font-size: 16px;
        @media (max-width: 1366px) {
          font-size: 15px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      .time-ago {
        font-size: 12px;
        color: #65676B;
      }

      .class-info {
        font-weight: 500;
        font-size: 14px;
        color: var(--primary);
        @media (max-width: 1366px) {
        }
        @media (max-width: 576px) {
          font-size: 13px;
        }
      }
    }
  }

  .question-content {
    text-align: justify;
    padding: 20px 0 16px;
    font-size: 16px;
    @media (max-width: 1366px) {
      padding: 10px 0;
      font-size: 15px;
    }
    @media (max-width: 576px) {
      padding: 10px 0;
      font-size: 14px;
    }

    .content-more {
      color: var(--primary);
      z-index: 99;
    }
  }

  .question-image {
    .image-container {
      background: #E2EBF6;
      border-radius: 4px;

      img {
        max-height: 300px;
        @media (max-width: 1366px) {
          max-height: 220px;
        }
        @media (max-width: 576px) {
          max-height: 200px;
        }
      }
    }
  }

  .line {
    height: 1px;
    background: #EBEBEB;
    margin-left: -24px;
    margin-right: -24px;
    @media (max-width: 1366px) {
      margin-left: -18px;
      margin-right: -18px;
    }
    @media (max-width: 576px) {
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .question-info {
    > [class*="col"] {
      padding-top: 16px;
      @media (max-width: 1366px) {
        padding-top: 8px;
      }
      @media (max-width: 576px) {
        padding-top: 10px;
      }

      .avatar-mask {
        img, .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;

          &.typing {
            left: 0;
            opacity: .8;
          }
        }
      }

      .answer-more {
        position: relative;

        img {
          margin-left: 0 !important;
          opacity: 0.5;
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          font-weight: bold;
        }
      }

      &.number-answer {
        font-weight: 500;
        font-size: 14px;
        color: #65676B;
      }

      &.action {
        button {
          background: var(--white);
          border: 1px solid var(--primary);
          border-radius: 22px;
          font-weight: 500;
          font-size: 14px;
          color: var(--primary);
          transition: all .4s;
          padding: 10px 40px 10px 32px;
          @media (max-width: 1366px) {
            padding: 8px 35px;
          }
          @media (max-width: 576px) {
            padding: 8px 35px;
          }

          &:disabled {
            visibility: hidden;
            display: none;
          }

          &:hover {
            background: var(--primary);
            color: var(--white);
          }
        }
      }
    }
  }
}
</style>
