<template>
  <div class="comment-item form-data" v-if="item">
    <template v-if="item.owner">
      <img :src="item.owner && item.owner.avatar || NoAvatar" class="avatar"/>
      <div class="content">
        <div class="user-name font-weight-bold text-break">{{ hidePhoneNumber(item.owner.fullName) }}</div>
        <div class="time-ago">{{ item.created_at | ralativeTime }}</div>
        <div class="content-comment">{{ item.content }}</div>
        <div v-if="item.image" class="">
          <div v-for="img in item.image" :key="img">
            <img :src="img" alt="image comment"/>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <img :src="item.avatar || NoAvatar" class="avatar"/>
      <div class="content">
        <div class="user-name font-weight-bold text-break">{{ hidePhoneNumber(item.fullName) }}</div>
        <div class="time-ago">{{ item.createdAtPHP | ralativeTime }}</div>
        <div class="content-comment">{{ item.content }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      NoAvatar
    }
  },
  methods: {
    hidePhoneNumber (name) {
      if (/^\d+$/.test(name)) {
        return name.substring(0, 6) + '***'
      } else {
        return name
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-item {
  .avatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
  }

  input {
    background: #EBEBEB;
    border-radius: 22px;
    padding: 14px 16px 14px 16px;
    font-size: 14px;
    color: #6B6D70;
    @media (max-width: 1366px) {
      padding: 10px 12px;
    }
  }

  .user-name {
    font-size: 16px;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
  }

  .time-ago {
    font-size: 12px;
    color: #65676B;
  }

  .content-comment {
    font-size: 16px;
    @media (max-width: 1366px) {
      font-size: 15px;
    }
  }
}
</style>
