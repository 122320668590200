<template>
  <b-dropdown v-if="item" no-caret :id="'dropdown-save-playlist-'+item.id" :ref="'dropdownSavePlaylist-'+item.id" right variant="tranparent p-0" class="dropdown-save-playlist" :class="classButton" @hide="$emit('close')" toggle-class="shadow-none">
    <div class="title">Lưu {{titleShow}} vào thư viện</div>
    <img class="close pointer position-absolute" src="~/mlearn/icons/modal/close-modal.svg" @click="closeDropdown"/>
    <div class="row playlist-item scrollbar">
      <div class="col-12" v-for="value in playlist" :key="value.id">
        <b-form-checkbox class="d-flex align-items-center" v-model="playlistId" :value="value.id" >{{value.name}}</b-form-checkbox>
      </div>
    </div>
    <div class="playlist-line"></div>
    <div class="row playlist-item">
      <div class="col-12 new-playlist">
        Tạo danh sách mới
      </div>
      <div class="col-12">
        <input class="playlist-name form-control" placeholder="Nhập tên danh sách" v-model="playlistName"/>
        <div v-if="textError" class="text-error">{{textError}}</div>
      </div>
    </div>
    <div class="action-new-playlist">
      <button class="btn" @click="createPlaylist">Tạo danh sách</button>
    </div>
  </b-dropdown>
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      default: 'exam_id',
      required: false
    },
    classButton: {
      type: String,
      default: '',
      required: false
    },
    open: {
      type: Boolean,
      default: false,
      required: false
    },
    item: {
      type: Object,
      default: null,
      required: true
    },
    playlist: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      textError: '',
      playlistName: '',
      playlistId: ''
    }
  },
  computed: {
    titleShow () {
      let title = ''
      switch (this.field) {
        case 'file_id':
          title = 'tài liệu'
          break
        case 'exam_id':
          title = 'bộ đề'
          break
        default:
          title = 'video'
          break
      }
      return title
    }
  },
  watch: {
    open () {
      if (this.open) {
        this.playlistId = ''
        this.textError = ''
        this.playlistName = ''
        this.openDropdown()
      } else {
        this.closeDropdown()
      }
    },
    playlistId () {
      if (this.playlistId) {
        const playlist = this.playlist.find(item => item.id === this.playlistId)
        if (!playlist) {
          const error = 'Playlist không tồn tại'
          this.emitError(error)
          return
        }
        const form = {
          playlist_id: this.playlistId,
          playlist_name: playlist.name
        }
        this.emitData(form)
      }
    }
  },
  methods: {
    openDropdown () {
      const refs = 'dropdownSavePlaylist-' + this.item.id
      this.$refs[refs].show(true)
    },
    closeDropdown () {
      const refs = 'dropdownSavePlaylist-' + this.item.id
      this.$refs[refs].hide(true)
    },
    createPlaylist () {
      this.playlistName = this.playlistName.trim()
      if (!this.playlistName) {
        this.textError = '*Vui lòng nhập tên danh sách'
        return
      }
      if (this.playlistName.length > 100) {
        this.textError = 'Tên danh sách tối đa 100 ký tự'
        return
      }
      if (this.$helper.specialCharacters(this.playlistName)) {
        this.textError = 'Tên danh sách không được chứa ký tự đặc biệt'
        return
      }
      this.textError = ''
      const form = {
        playlist_name: this.playlistName
      }
      this.emitData(form)
    },
    emitData (data) {
      if (this.field === 'video_id' || this.field === 'file_id') {
        data.file_id = this.item.id
      } else {
        data[this.field] = this.item.id
      }
      this.$emit('addData', data)
    },
    emitError (error) {
      this.$emit('showError', error)
    }
  }
}
</script>

<style lang="scss">
  .dropdown-save-playlist{
    position: absolute !important;
    ul{
      background: var(--white);
      border: 1px solid #E3E3E3;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      padding:24px;
      width: 328px;
      max-width: 100vw;
      @media (max-width:576px) {
        padding:16px;
      }
      .title{
        font-weight: bold;
        font-size: 18px;
        padding-bottom:10px;
        @media (max-width:576px) {
          font-size: 16px;
          padding-bottom:5px;
        }
        @media (max-width:576px) {
          font-size: 14px;
          padding-bottom:5px;
        }
      }
      .close{
        width:14px;
        height:14px;
        top:19px;
        right:19px;
        @media (max-width:576px) {
          top:16px;
          right:16px;
        }
      }
      .playlist-item{
        overflow: auto;
        &:first-child{
          max-height:150px;
          @media (max-width:576px) {}
        }
        label{
          text-align: center;
          cursor: pointer;
          word-break: break-word !important;
          word-wrap: break-word !important;
          font-size: 16px;
          padding-left: 10px;
          @media (max-width:576px) {
            font-size: 14px;
            padding-left: 5px;
          }
          &::before,&::after{
            width: 20px;
            height: 20px;
            @media (max-width:576px) {
              width: 15px;
              height: 15px;
            }
          }
        }
        .new-playlist{
          font-weight: 500;
          font-size: 13px;
          margin-bottom: 5px;
        }
        .playlist-name{
          background: #F0F2F5;
          border-radius: 4px;
          font-size: 16px;
          color: #868789;
          padding:8px 10px;
          box-shadow: none !important;
          @media (max-width:576px) {
            padding:8px 10px;
          }
        }
        .text-error{
          padding-top:8px;
          font-size: 13px;
          color: var(--danger);
          @media (max-width:576px) {}
        }
      }
      .playlist-line{
        border-top: 1px solid #EBEBEB;
        margin:10px 0;
      }
      .action-new-playlist{
        margin-top:15px;
        @media (max-width:576px) {}
        button{
          width: 100%;
          background: var(--primary);
          border-radius: 20px;
          height:40px;
          font-weight: 500;
          font-size: 16px;
          color: var(--white);
          padding:7px 0;
          text-align: center;
          @media (max-width:576px) {}
        }
      }
    }
  }
</style>
